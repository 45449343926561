<template>
  <div id="static">
    <vue-headful
      title="Learning center | Octoa"
    />
    <div class="text-center">
      <h1>How to use Octoa</h1>
      <h2>Everything you need to get started</h2>
    </div>

    <div class="content">
      <h3>1. Setting up your lead form</h3>
      <p>We can help you to setup your lead form which will be used on your own website.</p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="2ItCnf1rz1E"
        />
      </vue-plyr>

      <h3 class="mt-24">
        2. Overview of all projects
      </h3>
      <p>Oversee all your project activities, filter, search your projects.</p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="vbilmn82hp8"
        />
      </vue-plyr>

      <h3 class="mt-24">
        3. Start a new project
      </h3>
      <p>Got a new / existing client? Start a new project in either way.</p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="2ItCnf1rz1E"
        />
      </vue-plyr>

      <h3 class="mt-24">
        4. New Proposal or load from a template
      </h3>
      <p>Did you know you can load a proposal template before you start? </p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="bnBSmANceDE"
        />
      </vue-plyr>

      <h3 class="mt-24">
        5. Create a new proposal template
      </h3>
      <p>Using a template will save time across your workflow</p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="EvsUFO3fICY"
        />
      </vue-plyr>

      <h3 class="mt-24">
        6. Create a new proposal
      </h3>
      <p>Guide to options and step to create a new proposal.</p>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="1lhjGix1lE0"
        />
      </vue-plyr>

      <h3 class="mt-24">
        7. Invoice template
      </h3>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="5TAOA-km4JQ"
        />
      </vue-plyr>

      <h3 class="mt-24">
        8. Contract template
      </h3>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="GhiKrbgdQts"
        />
      </vue-plyr>

      <h3 class="mt-24">
        9. Product template
      </h3>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="sodsXLKpvi4"
        />
      </vue-plyr>

      <h3 class="mt-24">
        10. Email template
      </h3>
      <vue-plyr class="mt-5">
        <div
          data-plyr-provider="youtube"
          data-plyr-embed-id="JVUwRSt1LNs"
        />
      </vue-plyr>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      loading: true,

    }
  },
  methods: {

  }
}
</script>
